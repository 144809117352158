import { PDFDocument } from 'pdf-lib';
import lomavisCreator from '../../../redux/slices/lomavisCreator/lomavisCreator';
import {
  POST_TYPE_INFORMATION_NEWS,
  POST_TYPE_KEY,
  POST_TYPE_OFFER,
  POST_TYPE_PRODUCT,
  POST_TYPE_GALLERY_IMAGE
} from '../config/platformFeatures';
import smartcrop from 'smartcrop';
import heic2any from 'heic2any';
import Compressor from 'compressorjs';
import { RRule } from 'rrule';
import moment from 'moment-timezone';
import {
  IMAGE_QUALITY,
  MAX_IMAGE_HEIGHT,
  MAX_IMAGE_WIDTH
} from '../config/platformFeatures';
import { ALL } from '../../../config/constants';
import Pica from 'pica';
import { fileTypeFromBuffer } from 'file-type';
import piexif from 'piexifjs';
import { getBase64Strings } from 'exif-rotate-js';
import { fitBox } from 'fit-box';

interface Dimensions {
  width: number;
  height: number;
}

export const getImageDimensions = (url: string): Promise<Dimensions> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = reject;
    img.src = url;
  });
};

export const getVideoDimensions = (
  file: File
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');

    video.onloadedmetadata = () => {
      resolve({ width: video.videoWidth, height: video.videoHeight });
    };

    video.onerror = (error) => {
      reject(error);
    };

    const objectURL = URL.createObjectURL(file);
    video.src = objectURL;
  });
};

export const getPdfDimensions = async (
  file: File
): Promise<{ width: number; height: number }> => {
  const arrayBuffer = await file.arrayBuffer();
  const pdfDoc = await PDFDocument.load(arrayBuffer);
  const page = pdfDoc.getPage(0);
  const { width, height } = page.getSize();
  return {
    width: Math.round(width),
    height: Math.round(height)
  };
};

export const blobToFile = (blob, fileName) => {
  return new File([blob], fileName, {
    type: blob.type,
    lastModified: Date.now()
  });
};
export const blobToPNGFile = (blob, fileName) => {
  return new File([blob], fileName, {
    type: 'image/png',
    lastModified: Date.now()
  });
};

export async function urlToFileTypeData(url, filename, mimeType) {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], filename, { type: mimeType });
}
export function createMockFile({
  original_filename,
  file_type,
  file_size,
  original_asset,
  video_audio_channels,
  pdf_page_count
}) {
  const fileName = `${original_filename}`;
  const mimeType = file_type;

  // Simulate the File object
  const file = {
    name: fileName,
    type: mimeType,
    size: file_size,
    url: original_asset,
    video_audio_channels: video_audio_channels,
    pdf_page_count: pdf_page_count,
    lastModified: new Date().getTime(),
    // Add any other properties or methods as needed
    slice: () => new Blob([original_asset], { type: mimeType }) // You can mock slice to return an empty Blob or similar
  };

  return file;
}

export const getPostTypesList = (lomavisCreator: any) => {
  const postTypesList = [POST_TYPE_KEY];
  const { cloudUuid, locationUuid } = lomavisCreator;
  if (cloudUuid) {
    postTypesList.push(
      ...[POST_TYPE_PRODUCT, POST_TYPE_OFFER, POST_TYPE_INFORMATION_NEWS]
    );
  }
  return postTypesList;
};

export function removeObjectsByKeys(object, keysToRemove) {
  return Object.keys(object).reduce((result, key) => {
    if (!keysToRemove.includes(key)) {
      result[key] = object[key];
    }
    return result;
  }, {});
}

export const getSmartCrop = async (
  imageUrl,
  aspectRatioWidth,
  aspectRatioHeight
) => {
  const img = new Image();
  img.crossOrigin = 'Anonymous'; // Handle CORS issues
  img.src = imageUrl;

  return new Promise((resolve, reject) => {
    img.onload = async () => {
      try {
        const { width, height } = img;

        if (!aspectRatioWidth || !aspectRatioHeight) {
          resolve({
            x: 0,
            y: 0,
            width: width,
            height: height
          });
        }

        const cropOptions = {
          width: aspectRatioWidth,
          height: aspectRatioHeight
        };

        const result = await smartcrop.crop(img, cropOptions);
        const crop = result.topCrop;

        resolve({
          x: crop.x,
          y: crop.y,
          width: crop.width,
          height: crop.height
        });
      } catch (error) {
        reject(error);
      }
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
};

// export const getSmartCrop = async (imageUrl, aspectRatio) => {
//   const img = new Image();
//   img.crossOrigin = "Anonymous"; // Handle CORS issues
//   img.src = imageUrl;

//   return new Promise((resolve, reject) => {
//     img.onload = async () => {
//       try {
//         const { width, height } = img;
//         const cropWidth = Math.min(width, height * aspectRatio);
//         const cropHeight = Math.min(height, width / aspectRatio);

//         const cropOptions = {
//           width: cropWidth,
//           height: cropHeight,
//         };

//         const result = await smartcrop.crop(img, cropOptions);
//         const crop = result.topCrop;

//         resolve({
//           x: crop.x,
//           y: crop.y,
//           width: crop.width,
//           height: crop.height,
//         });
//       } catch (error) {
//         reject(error);
//       }
//     };

//     img.onerror = (error) => {
//       reject(error);
//     };
//   });
// };

// Convert ArrayBuffer to a binary string

// Function to read EXIF data from a file
export const getRealFileType = async (
  file: File
): Promise<{ ext: string; mime: string } | null> => {
  const arrayBuffer = await file.arrayBuffer(); // Convert File to ArrayBuffer
  const buffer = new Uint8Array(arrayBuffer); // Convert ArrayBuffer to Buffer (Uint8Array)

  const fileType = await fileTypeFromBuffer(buffer); // Identify the file type

  if (fileType) {
    return fileType; // { ext: 'jpg', mime: 'image/jpeg' } or null if not recognized
  } else {
    console.warn('Unable to determine the file type.');
    return null;
  }
};

export const convertHeicToJpeg = async (heicFile) => {
  try {
    const jpegBlob = await heic2any({
      blob: heicFile,
      toType: 'image/jpeg',
      quality: 1 // you can set the quality of the output JPEG file
    });

    // Check if the result is an array and use the first element if it is
    const jpegBlobSingle = Array.isArray(jpegBlob) ? jpegBlob[0] : jpegBlob;

    // Create a new File object from the JPEG Blob
    const jpegFile = new File(
      [jpegBlobSingle],
      heicFile.name.replace(/\.[^/.]+$/, '.jpg'),
      {
        type: 'image/jpeg',
        lastModified: Date.now()
      }
    );

    return jpegFile;
  } catch (error) {
    if (error?.code == 1) {
      const fileInfo = await getRealFileType(heicFile);

      if (fileInfo.mime === 'image/heic') {
        console.error('Conversion failed:', error);
        throw error;
      }

      const oldNameWithoutExtension = heicFile.name.replace(/\.[^/.]+$/, ''); // Remove the old extension
      const newFileName = `${oldNameWithoutExtension}.${fileInfo.ext}`; // Append the new extension

      // Create a new File object with updated type and name
      const newFile = new File([heicFile], newFileName, {
        type: fileInfo?.mime, // Update MIME type
        lastModified: heicFile.lastModified
      });

      return newFile;
    }
    console.error('Conversion failed:', error);
    throw error;
  }
};

export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

// Helper function to convert Base64 to Blob
export const base64ToBlob = (base64: string, type: string): Blob => {
  const byteString = atob(base64.split(',')[1]); // Decode Base64 string
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  return new Blob([uint8Array], { type });
};

export const compressImage = async (file: File) => {
  const MAX_DIMENSION = 1920;
  const pica = Pica();
  const sourceCanvas = document.createElement('canvas');
  const targetCanvas = document.createElement('canvas');

  // Convert the image file to Base64 (with EXIF rotation, if needed)

  const image = new Image();
  image.src = URL.createObjectURL(file);

  return new Promise<File>((resolve, reject) => {
    image.onload = async () => {
      // Set up the source canvas to match the image dimensions
      sourceCanvas.width = image.width;
      sourceCanvas.height = image.height;

      // Draw the image onto the source canvas
      const context = sourceCanvas.getContext('2d');
      if (!context) {
        reject(new Error('Failed to get 2D context for source canvas.'));
        return;
      }
      context.drawImage(image, 0, 0);

      // Calculate target dimensions
      let width = image.width;
      let height = image.height;

      if (width > MAX_DIMENSION || height > MAX_DIMENSION) {
        const newDimensions = fitBox({
          boundary: { width: MAX_DIMENSION, height: MAX_DIMENSION },
          box: { width: width, height: height }
        });
        width = newDimensions.width;
        height = newDimensions.height;
      }

      // Set up the target canvas
      targetCanvas.width = Math.round(width);
      targetCanvas.height = Math.round(height);

      try {
        // Resize using Pica with the source canvas as input
        const resultCanvas = await pica.resize(sourceCanvas, targetCanvas);

        // Convert the resized canvas to a Blob
        const blob = await pica.toBlob(resultCanvas, file.type, 1);

        // Create a compressed file from the Blob
        const compressedFile = new File([blob], file.name, {
          type: file.type,
          lastModified: Date.now()
        });

        resolve(compressedFile);
      } catch (error) {
        console.error('Error during image compression:', error);
        reject(error);
      }
    };

    image.onerror = (error) => {
      console.error('Error loading image:', error);
      reject(error);
    };
  });
};

export async function scaleDownImage(
  file: File,
  maxWidth: number = 1920,
  maxHeight: number = 1920
): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      let { width, height } = img;

      // Calculate the scaling factor while maintaining aspect ratio
      const aspectRatio = width / height;
      if (width > maxWidth || height > maxHeight) {
        if (aspectRatio > 1) {
          // Landscape image
          width = maxWidth;
          height = maxWidth / aspectRatio;
        } else {
          // Portrait image
          height = maxHeight;
          width = maxHeight * aspectRatio;
        }
      }

      // Create a canvas and draw the resized image
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');

      if (ctx) {
        ctx.drawImage(img, 0, 0, width, height);

        // Convert the canvas back to a blob
        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error('Could not scale down the image.'));
            }
          },
          file.type, // Preserve the original file type (e.g., 'image/jpeg')
          1 // Set image quality if applicable (e.g., JPEG compression)
        );
      } else {
        reject(new Error('Canvas context not available.'));
      }
    };

    img.onerror = () => {
      reject(new Error('Failed to load the image.'));
    };
  });
}

export function decimalToRatio(decimal: number): {
  width: number;
  height: number;
} {
  const gcd = (a: number, b: number): number => {
    if (!b) return a;
    return gcd(b, a % b);
  };

  const tolerance = 1.0e-6;
  let h1 = 1,
    h2 = 0,
    k1 = 0,
    k2 = 1,
    b = decimal;

  do {
    const a = Math.floor(b);
    const aux = h1;
    h1 = a * h1 + h2;
    h2 = aux;
    const aux2 = k1;
    k1 = a * k1 + k2;
    k2 = aux2;
    b = 1 / (b - a);
  } while (Math.abs(decimal - h1 / k1) > decimal * tolerance);

  const gcdValue = gcd(h1, k1);

  return { width: h1 / gcdValue, height: k1 / gcdValue };
}

// Function to get platforms with content available for preview
export const getPlatformsWithPreview = (lomavisCreatorState) => {
  // Extract platform keys from postData and filter based on platformEnabled flag
  return Object.keys(lomavisCreatorState.postData)
    .filter((platform) => lomavisCreatorState?.platformEnabled[platform])
    .filter((platform) => {
      // Exclude the "ALL" platform
      if (platform === ALL) return false;
      // Include platforms that have either media or text content
      return (
        lomavisCreatorState.postData[platform]?.media?.filter(
          (file) => file?.uploaded
        ).length > 0 ||
        lomavisCreatorState.postData[platform]?.text_length_striped > 0
      );
    });
};

// Function to check if there are any platforms with content available for preview
export const showPreview = (lomavisCreatorState) => {
  // Returns true if at least one platform has content, false otherwise
  return getPlatformsWithPreview(lomavisCreatorState).length > 0;
};

export const getObjectAsFile = (item) => {
  const mockFileData = {
    original_filename: item?.name,
    file_type: item?.file_type,
    file_size: item?.file_size,
    original_asset: item?.original_asset,
    video_audio_channels: item?.video_audio_channels,
    pdf_page_count: item?.pdf_page_count
  };

  const mockFile = createMockFile(mockFileData);
  return mockFile;
};

export const updatePlatformStatus = (platforms, platformKey, state) => {
  // Return a new object with the updated boolean value for the specified platformKey
  return {
    ...platforms, // Spread the original platforms
    [platformKey]: state // Update the specified platform's boolean value
  };
};

export const determineImageDimensionsFromURL = (
  previewImageURL: string
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      resolve({
        width: img.naturalWidth,
        height: img.naturalHeight
      });
    };

    img.onerror = (error) => {
      reject(error);
    };

    // Directly set the src to the preview image URL
    img.src = previewImageURL;
  });
};

export function parseString(value) {
  if (value === 'null') return null;
  if (/^".*"$/.test(value)) return value.slice(1, -1);
  return value;
}

export function updateRecurrenceTime({ time, recurrence_as_str, timezone }) {
  const [hours, minutes] = time.split(':').map(Number);

  // Parse the existing recurrence rule
  const rule = RRule.fromString(recurrence_as_str);

  // Get the current DTSTART and update its time in the given timezone
  let dtStart = moment(rule.options.dtstart).tz(timezone).set({
    hour: hours,
    minute: minutes,
    second: 0,
    millisecond: 0
  });

  // Update UNTIL if it exists
  let until = rule.options.until
    ? moment(rule.options.until).tz(timezone).set({
        hour: hours,
        minute: minutes,
        second: 0,
        millisecond: 0
      })
    : null;

  // Convert back to native JS Date for RRule
  const updatedRule = new RRule({
    ...rule.origOptions,
    dtstart: dtStart.toDate(),
    until: until ? until.toDate() : undefined
  });

  return updatedRule.toString();
}

export function extractTimeFromRecurrence(recurrence_as_str) {
  // Parse the rule from the recurrence string
  const rule = RRule.fromString(recurrence_as_str);

  // Get the DTSTART time
  const dtStart = rule.options.dtstart;

  if (!dtStart) {
    throw new Error('DTSTART is missing from the recurrence string.');
  }

  // Format the time as HH:mm
  const hours = dtStart.getUTCHours().toString().padStart(2, '0');
  const minutes = dtStart.getUTCMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`;
}
